import Checkbox from '@/components/checkbox';
import SendIcon from '@/components/icons/send';
import MainLayout from '@/components/main-layout';
import React from 'react';
import { useSnackbar } from 'notistack';
import SEO from '@/components/seo';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const ContactForm: React.FC = () => {
  const [privacyAgreement, setPrivacyAgreement] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      setLoading(true);
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': event.target.getAttribute('name'),
          name,
          email,
          message,
        }),
      });
      setName('');
      setEmail('');
      setMessage('');
      setPrivacyAgreement(false);
      enqueueSnackbar('Správa bola úspešne odoslaná!', {
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      });
    } catch (e) {
      enqueueSnackbar('Neočakávaná chyba! Prosím, skúste to neskôr.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className="flex flex-col px-4 py-10 lg:p-10 bg-white text-black shadow-xl relative"
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="contact"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <h2 className="mb-4 font-bold text-lg">Kontaktujte nás</h2>
      <div className="absolute bottom-0 right-4 z-0" style={{ opacity: 0.2 }}>
        <SendIcon />
      </div>
      <input
        type="text"
        value={name}
        onChange={(event) => setName(event.target.value)}
        name="name"
        placeholder="Meno"
        className="mb-2 p-2 border-b-2 border-gray-300 outline-none"
      />
      <input
        type="email"
        name="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        placeholder="Emailová adresa"
        className="mb-2 p-2 border-b-2 border-gray-300 outline-none"
      />
      <textarea
        name="message"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        cols={30}
        rows={5}
        placeholder="Vaša správa"
        className="p-2 border-b-2 border-gray-300 outline-none"
      />
      <div className="mt-4 z-30">
        <Checkbox
          label="Súhlas so spracovaním osobných údajov"
          checked={privacyAgreement}
          onChange={setPrivacyAgreement}
        />
      </div>
      <button
        type="submit"
        disabled={
          !privacyAgreement ||
          !name?.trim() ||
          !email ||
          !message?.trim() ||
          !emailPattern.test(email) ||
          loading
        }
        className="text-white p-4 mt-6 z-30 disabled:opacity-50 transition-all"
        style={{ backgroundColor: '#0f161d' }}
      >
        Odoslať
      </button>
    </form>
  );
};

const Kontakt: React.FC = () => (
  <>
    <SEO
      title="Kontakt"
      description="Kontaktujte nás pomocou formulára."
      image="/contact.jpg"
    />
    <MainLayout>
      <div className="flex flex-col lg:flex-row justify-center container m-auto text-white md:px-20 px-4 py-20">
        <div
          className="mb-10 lg:mb-0 lg:mr-10 px-4 py-10 lg:p-10 shadow-xl relative overflow-hidden"
          style={{ backgroundColor: '#0f161d' }}
        >
          <h1
            className="absolute -left-2 top-0 cursor-default"
            style={{ fontSize: '120px', opacity: 0.05, fontWeight: 900 }}
          >
            KONTAKT
          </h1>
          <h1 className="mb-16 mt-6 text-3xl font-semibold">KONTAKT</h1>
          <div className="mb-6">Ing. Jaroslav Zelenák</div>
          <div className="mb-2">
            <a href="tel:+421904035844">+421 904 035 844</a>
          </div>
          <div className="mb-2">
            <a href="mailto:jaroslav.zelenak@bimhouse.sk">
              jaroslav.zelenak@bimhouse.sk
            </a>
          </div>
          <div className="mb-6">
            Považská Teplá 572, 017 05, Považská Bystrica, Slovenská republika
          </div>
          <div className="mb-2">IČO: 48336416</div>
          <div>DIČ: 1121237535</div>
        </div>
        <ContactForm />
      </div>
    </MainLayout>
  </>
);

export default Kontakt;
