import React from 'react';
import _JSXStyle from 'styled-jsx/style';

type CheckboxProps = {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange }) => {
  React.useEffect(() => {
    // fix for styled-jsx
    console.log(_JSXStyle);
  }, []);

  return (
    <>
      <div className="flex">
        <label htmlFor="checkbox" className="checkbox path">
          <input
            id="checkbox"
            type="checkbox"
            checked={checked}
            onChange={() => {
              onChange(!checked);
            }}
          />
          <svg viewBox="0 0 21 21">
            <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
          </svg>
        </label>
        <label htmlFor="checkbox" className="cursor-pointer ml-2">
          {label}
        </label>
      </div>
      <style jsx>{`
        .checkbox {
          --background: #fff;
          --border: #d1d6ee;
          --border-hover: #bbc1e1;
          --border-active: #1e2235;
          --tick: #fff;
          position: relative;
        }
        .checkbox input,
        .checkbox svg {
          width: 21px;
          height: 21px;
          display: block;
        }
        .checkbox input {
          -webkit-appearance: none;
          -moz-appearance: none;
          position: relative;
          outline: none;
          background: var(--background);
          border: none;
          margin: 0;
          padding: 0;
          cursor: pointer;
          border-radius: 4px;
          transition: box-shadow 0.3s;
          box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
        }
        .checkbox input:hover {
          --s: 2px;
          --b: var(--border-hover);
        }
        .checkbox input:checked {
          --b: var(--border-active);
        }
        .checkbox svg {
          pointer-events: none;
          fill: none;
          stroke-width: 2px;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke: var(--stroke, var(--border-active));
          position: absolute;
          top: 0;
          left: 0;
          width: 21px;
          height: 21px;
          transform: scale(var(--scale, 1)) translateZ(0);
        }
        .checkbox.path input:checked {
          --s: 2px;
          transition-delay: 0.4s;
        }
        .checkbox.path input:checked + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
        .checkbox.path svg {
          stroke-dasharray: var(--a, 86.12);
          stroke-dashoffset: var(--o, 86.12);
          transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
        }
      `}</style>
    </>
  );
};

export default Checkbox;
